import styled from '@emotion/styled';
import Link from 'next/link';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { gsap, Power1 } from 'gsap';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { RectangleBlur, slideIn } from 'styles/styled';
import { breakPoints } from 'constant/breakPoints';
import { APP_ROUTES } from 'constant/appConstant';

function WhoWeAre(): JSX.Element {
  const selfRef = useRef<HTMLElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLDivElement>(null);

  const [isViewVideo, setIsViewVideo] = useState(false);

  const entry = useIntersectionObserver(selfRef, {
    threshold: 0.5,
    freezeOnceVisible: true,
  });

  const playButtonRotateTL = useMemo(() => {
    if (!process.browser) return;
    const result = {
      init: function () {
        const gsapTimeline = gsap.timeline({ paused: true });
        gsapTimeline
          .to(
            '.play-button-circle-01',
            {
              duration: 0.7,
              opacity: 0.1,
              rotation: '+=360',
              strokeDasharray: '456 456', // 2*Math.PI * (svg <circle /> r)
              ease: Power1.easeInOut,
            },
            0,
          )
          .to(
            '.play-button-circle-02',
            {
              duration: 0.7,
              opacity: 0.1,
              rotation: '-=360',
              strokeDasharray: '411 411',
              ease: Power1.easeInOut,
            },
            0,
          );
        this.timeline = gsapTimeline;
        return gsapTimeline;
      },
      timeline: undefined as undefined | gsap.core.Timeline,
    };

    return result;
  }, []);

  const handlePlayMouseOver = () => {
    if (!playButtonRotateTL?.timeline) playButtonRotateTL?.init();
    playButtonRotateTL?.timeline?.play();
  };

  const handleEffectSlideIn = useCallback(() => {
    const isInViewport = !!entry?.isIntersecting;
    if (!isInViewport) return;

    introRef.current?.classList.add('mount');
    videoRef.current?.classList.add('mount');
  }, [entry?.isIntersecting]);

  const handlePlayMouseLeave = () => {
    if (!playButtonRotateTL?.timeline) playButtonRotateTL?.init();
    playButtonRotateTL?.timeline?.reverse();
  };

  const onPlayVideo = () => {
    setIsViewVideo(true);
    document.querySelector('body')?.classList.add('stop-scrolling');
  };

  const onCloseVideo = () => {
    setIsViewVideo(false);
    document.querySelector('body')?.classList.remove('stop-scrolling');
  };

  useEffect(() => {
    gsap.set('.play-button-circle-01', {
      rotate: 90,
      transformOrigin: 'center',
    });
    gsap.set('.play-button-circle-02', {
      rotate: -90,
      transformOrigin: 'center',
    });
  }, []);

  useEffect(() => {
    handleEffectSlideIn();
  }, [handleEffectSlideIn]);

  return (
    <WrapMark>
      <RectangleBlur
        width="350px"
        maxWidth="350px"
        rotate={-142.36}
        left="-220px"
        bottom="-104px"
      />
      <h6 className="title-section">WHO WE ARE</h6>
      <Wrap className="layout-wrap" ref={selfRef}>
        <Introduction ref={introRef}>
          <div>
            <h4 className="font-semibold">
              20+ YEARS IN <br /> DIGITAL SOLUTIONS
            </h4>

            <p className="text-gray3 typo1">
              Spirit Labs is a full-stack software development company
              specializing in offering digital business solutions by leveraging
              cutting-edge technologies, with extensive experience of being the
              trusted partner for SMEs and industry players from different
              regions Asia, Europe, America.
            </p>

            <Link href={APP_ROUTES.SERVICES} prefetch={false}>
              <a className="font-medium">Our Services</a>
            </Link>
          </div>
        </Introduction>

        <VideoWrap ref={videoRef}>
          <div className="video-thumb">
            <img
              src="/images/home/who-we-are.webp"
              alt="Spiritlabs Who we are"
            />
          </div>

          <div
            className="play-button"
            onMouseOver={handlePlayMouseOver}
            onMouseLeave={handlePlayMouseLeave}
            onClick={onPlayVideo}
          >
            <svg className="play-button-circles" viewBox="0 0 152 152">
              <path
                d="M 87.584 79.2 L 67.584 91.2 L 67.584 67.2 L 87.584 79.2 Z"
                fill="white"
              />
              <circle
                className="play-button-circle-01"
                fill="none"
                stroke="#fff"
                strokeWidth="4"
                strokeDasharray="343 343"
                cx="76"
                cy="76"
                r="72.7"
              />
              <circle
                className="play-button-circle-02"
                fill="none"
                stroke="#fff"
                strokeWidth="4"
                strokeDasharray="309 309"
                cx="76"
                cy="76"
                r="65.5"
              />
            </svg>
          </div>
        </VideoWrap>
      </Wrap>
      {isViewVideo && (
        <EmbedYoutube>
          <div className="embed-youtube-wrap">
            <button onClick={onCloseVideo}></button>
            <div className="embed-youtube">
              <iframe
                src="https://www.youtube.com/embed/jHjLyMcGSSc"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              />
            </div>
          </div>
        </EmbedYoutube>
      )}
    </WrapMark>
  );
}

export default WhoWeAre;

const WrapMark = styled.div`
  position: relative;
  margin-top: 136px;

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    & > div:nth-of-type(1) {
      display: none;
    }

    .title-section {
      margin-bottom: 20px;
    }
  }
`;

const Wrap = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    display: flex;
    justify-content: center;
  }
`;

const Introduction = styled.div`
  max-width: 507px;
  padding: 50px 0;

  h6 {
    margin: 0 0 8px;
    font-size: 24px;
    position: relative;
    padding-left: 100px;

    transform: translateY(20px);
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      height: 2px;
      width: 75px;
      transform: translateY(-50%);
      background-color: var(--yellow);
    }
  }

  h4 {
    margin: 0 0 16px;
    font-size: 28px;
  }

  a {
    display: inline-block;
    margin-top: 40px;
    line-height: 52px;
    border: 2px solid var(--white);
    padding: 0 30px;
    border-radius: 100px;
    font-size: 16px;
    margin-bottom: 20px;

    &:hover {
      background-color: var(--white);
      color: var(--purple-darken);
    }
  }

  > div {
    transform: translateY(20%);
  }

  /* START-animation */
  &.mount {
    h6 {
      ${slideIn}
    }

    > div {
      ${slideIn}
    }
  }
  /* END-animation */

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    padding-top: 5px;
    text-align: center;

    h4 {
      font-size: 20px;
    }
  }
`;

const VideoWrap = styled.div`
  width: 38.055vw;
  max-width: 548px;
  position: relative;
  padding-top: 57%;
  cursor: pointer;
  transform: translateY(20%);
  opacity: 0;

  .video-thumb {
    overflow: hidden;
    position: absolute;
    inset: 0;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &:before {
    top: -32px;
    left: -32px;
    background: linear-gradient(180deg, #3c235c 0%, rgba(60, 35, 92, 0) 100%);
  }

  &:after {
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    overflow: hidden;
  }

  &:hover img {
    transform: scale(1.15);
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80px;
    width: 80px;
    z-index: 3;

    &-circle-01 {
      transform: matrix(0, 1, -1, 0, 152, 0);
    }

    &-circle-02 {
      transform: matrix(0, -1, 1, 0, 0, 152);
    }
  }

  &.mount {
    ${slideIn}
  }

  @media screen and (max-width: ${breakPoints.tabletM}px) {
    width: calc(100% - 16px);
    padding-top: 117%;
    margin-left: 16px;

    &:before {
      top: -16px;
      left: -16px;
    }
  }
`;

const EmbedYoutube = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);

  .embed-youtube-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 32px);
    max-width: 900px;

    & > button {
      position: absolute;
      bottom: 100%;
      right: 0;
      background-color: #000;
      width: 40px;
      height: 40px;
      border: none;

      &:before,
      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 2px;
        background-color: #fff;
        top: calc(50% - 1px);
        left: calc(50% - 10px);
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .embed-youtube {
      width: 100%;
      padding-top: 56.23%;
      background-color: #000;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
`;
